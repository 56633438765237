/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
// import videojs from 'video.js'
// import { useEffectOnce } from 'react-use'

import styles from './Renderings.module.scss'
import { Video, styles as interviewStyles } from './Interview'
// import Page from './Page'
// import Popup from './Popup'
// import IconPlay from '../assets/IconPlay'

export const Renderings = ({ designer }) => {
	// const videoRef = useRef(null)
	// const mobile = false // useWindowWidth() <= 800

	// useEffectOnce(() => {
	// 	if (videoRef.current)
	// 		videojs(videoRef.current, { children: ['mediaLoader'] }).addClass(styles.video)
	// })

	// const [ref, { x, y, width }] = useDimensions()

	return <div className={styles.root} style={{ backgroundImage: `url(${designer.designer_renderings.poster})` }}>
		<Video
			src={designer.designer_renderings.video}
			options={{
				muted:    true,
				autoplay: true,
				loop:     true,
			}}
			zoom
		/>
	</div>

	// if (mobile) {
	// 	return <Page>
	// 		<Popup
	// 			toggle={<div
	// 				style={{ backgroundImage: `url(${designer.designer_renderings.poster})` }}
	// 				className={interviewStyles.toggle}
	// 			>
	// 				<IconPlay />
	// 			</div>}
	// 			popup={}
	// 		/>
	// 	</Page>
	// } else {
	// 	return <div className={styles.root}>
	// 		<video
	// 			ref={videoRef}
	// 			muted
	// 			autoPlay
	// 			loop
	// 			playsInline
	// 			title="Intro Mask Video"
	// 		>
	// 			<source src={designer.designer_renderings.video} type="application/x-mpegURL" />
	// 		</video>
	// 	</div>
	// }
}
