/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react'
import styles from './ProfileNav.module.scss'
import { useEffectOnce, useLocation } from 'react-use'
import videojs from 'video.js'
import { useHistory } from 'react-router'

import Config from './assets.json'
import DesignerNav from './DesignerNav'
import ProfileGestureRoot from './ProfileGestureRoot'
import Profile from './Profile'
import { setPageHeight, setProfileSwipe } from './events'
import { useDrag } from 'react-use-gesture'
import useTriggerEffect from '../../../../../vendor/react-use-trigger/useTriggerEffect'
import { showProfiles, skewProfiles } from './breakpoints'


function preventAllDrag(e) {
	e.preventDefault()
}


const ProfileNav = ({ y, zIndex, page = false, currentDesignerID, mobile }) => {
	let el
	const [designer, setDesigner] = useState(null)
	const history = useHistory()
	const location = useLocation()
	const [transition, setTransition] = useState(false)
	const [navigating, setNavigating] = useState(false)
	const [player, setPlayer] = useState(null)
	const videoRef = useRef(null)

	let tw = 0

	if (page) {
		tw = 1
	} else {
		if (y > skewProfiles) {
			const length = 300
			const diff = Math.min(y - skewProfiles, length)
			const scale = diff / length
			// console.log({ diff, scale })
			tw = scale
		}
	}

	useEffect(() => {
		if (designer) {
			document.removeEventListener('touchmove', preventAllDrag)
			document.addEventListener('touchmove', preventAllDrag, { passive: false })
		} else {
			document.removeEventListener('touchmove', preventAllDrag)
		}

		return () => document.removeEventListener('touchmove', preventAllDrag)
	}, [designer !== null])

	useEffect(() => {
		if ((!page || location.pathname === '/profiles') && designer) {
			return setDesigner(null)
		}
	}, [location.pathname])

	useEffect(() => {
		if (designer && mobile) {
			setPageHeight('WINDOW_HEIGHT')
		} else {
			setPageHeight(0)
		}

	}, [designer, mobile])

	useEffect(() => page && setTimeout(() => setTransition(true), 500), [page])

	useEffect(() => {
		if (!designer) {
			if (player)
				player.addClass(styles.hideBackgroundVideo)

			return
		}

		if (!player) {
			let player = videojs(videoRef.current, {
				autoplay: true,
				children: ['mediaLoader'],
			})

			setPlayer(player)
			player.addClass(styles.backgroundVideo)
			player.src(designer.background_video)
		} else {
			player.removeClass(styles.hideBackgroundVideo)
			player.src(designer.background_video)
		}

	}, [designer && designer.background_video])

	useEffect(() => {
		if (!page) return

		if (mobile) {
			window.scrollTo(0, 400)
			setTimeout(() => window.scrollTo(0, 400), 500)
		}
	}, [mobile, page])

	useEffectOnce(() => Config.profiles.map(profile => {
		new Image().src = profile.background_image
		new Image().src = profile.profile
	}))

	useEffect(() => {
		if (currentDesignerID.length > 0) {
			let d = Config.profiles.find(p => p.id === currentDesignerID)

			transitionTo(d)()
		}
	}, [currentDesignerID])

	const transitionTo = profile => () => {
		if (navigating) return

		setNavigating(true)
		const offset = 100
		setTimeout(() => setDesigner(profile), offset)
		setTimeout(() => setNavigating(false), offset + 1000)
		history.push(`/profiles/${profile.id}`)
	}

	const maxIndex = Config.profiles.length - 1
	const currentIndex = Config.profiles.indexOf(designer)
	const prevIndex = currentIndex == 0 ? maxIndex : currentIndex - 1
	const nextIndex = currentIndex == maxIndex ? 0 : currentIndex + 1
	const backgroundImage = designer != null && `url(${designer.background_image})`

	useTriggerEffect(ySwipe => {
		if (!designer) {
			return
		}

		if (ySwipe !== 0) {
			if (ySwipe === 1 && currentIndex !== 0) {
				transitionTo(Config.profiles[prevIndex])()
			} else if (ySwipe === -1 && currentIndex !== maxIndex) {
				transitionTo(Config.profiles[nextIndex])()
			}

			setProfileSwipe(0)
		}
	}, setProfileSwipe)

	const bind = useDrag(({ down, movement: [mx], /*direction: [xDir],*/ swipe: [xSwipe, ySwipe], /*distance,*/ cancel }) => {
		if (ySwipe !== 0) {
			setProfileSwipe(ySwipe)
			cancel()
		}
	})

	const startMaskScale = 25
	const maskScaleLength = 700
	const diff = Math.max(y - showProfiles - 290, 0)
	const scale = Math.min(Math.max(maskScaleLength - diff, 0), maskScaleLength) / maskScaleLength * startMaskScale

	return <div
		className={styles.root + ' ' + (page && styles.page)}
		style={{
			zIndex,
			backgroundImage,
			WebkitMaskSize: !mobile && `100%, ${scale}rem ${scale}rem`,
		}}
		{...bind()}
		ref={el}
	>
		<video ref={videoRef} autoPlay loop muted playsInline>
			<source type="application/x-mpegURL" />
		</video>

		{designer != null && <>
			<DesignerNav designer={designer} mobile={mobile} navigating={navigating} />
			<ProfileGestureRoot key={designer.id} designer={designer} navigating={navigating} />
		</>}

		<div className={styles.profileList}>
			{Config.profiles.map((profile, i) =>
				<Profile
					current={designer === profile}
					currentIndex={currentIndex}
					isNext={designer != null && nextIndex === i}
					isPrev={designer != null && prevIndex === i}
					isSelected={designer != null}
					key={i}
					mobile={mobile}
					navigating={navigating}
					onClick={transitionTo(profile)}
					page={page}
					pos={i}
					profile={profile}
					steps={Config.profiles.length}
					transition={transition}
					tw={tw}
					y={y}
				/>,
			)}
		</div>
	</div>
}

export default ProfileNav
