/* eslint-disable react/prop-types */
import React from 'react'
import { useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import Config from './assets.json'
import styles from './IntroBackground.module.scss'
import Video from './Video'
import videojs from 'video.js'

const IntroBackground = ({ y, playing, paused }) =>{
	const playerId = 'intro-background-video'
	let player = videojs.getPlayer(playerId)

	useEffectOnce(() => {
		player = videojs.getPlayer(playerId)
		player.play()
	})

	useEffect(() => {
		// console.log('IntroBackground - useEffect - player', player)

		// console.log('')
		// console.log('-----------------------------------')
		// console.log('IntroBackground - useEffect - y', y)
		// console.log('IntroBackground - useEffect - paused', paused)
		// console.log('IntroBackground - useEffect - playing', playing)
		// console.log('-----------------------------------')
		// console.log('')

		if (player) {
			if (y > paused) {
				// console.log('pausing...')
				player.pause()
			} else if (y >= playing) {
				// console.log('playing...')
				player.play()
			}
		} else {
			// console.log('PLAYER NOT FOUND')
		}
	}, [(y > paused)])

	return <Video
		playerId={playerId}
		containerClass={styles.root}
		poster={Config.intro.intro_videos[0].intro_background_poster}
		src={Config.intro.intro_videos[0].intro_background_video}
		type="application/x-mpegURL"
	/>
}

export default IntroBackground
