/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { useEffect } from 'react'
import videojs from 'video.js'

const Video = ({ refId, className, src, type, poster, containerClass, playerId }) => {
	// Types:
	//   • Streaming / .m3u8 files `application/x-mpegURL`
	// 	 • .mp4 files `video/mp4`

	const videoRef = useRef(refId)

	const setPlayerId = (customId = null) => {
		if (customId) { return customId }

		let randomNumber = Math.floor(Math.random() * 100000)

		return `videojs-player-${randomNumber}`
	}

	useEffect(() => {
		console.log('poster: ', poster)

		const player = videojs(videoRef.current, {
			controls:      false,
			controlBar:    false,
			bigPlayButton: false,
			muted:         true,
			autoplay:      true,
			playsinline:   true,
			poster:        poster,
			preload:       'auto',
			loop:          true,
			children:      ['mediaLoader'],
		})

		player.addClass(className)
		player.src({ type: type, src: src })
	}, [src])

	return <div className={containerClass}>
		<div data-vjs-player>
			<video
				id={setPlayerId(playerId)}
				ref={videoRef}
				poster={poster}
			>
				<source src={src} type={type} />
			</video>
		</div>
	</div>
}

export default Video